import React, { HTMLProps } from 'react';

const UnorderedList = ({ children, className }: HTMLProps<HTMLUListElement>) => {
  const ULClassList: string = className ? className : "ml-8 list-disc";

  return <ul className={ULClassList}>{children}</ul>;
};

export default UnorderedList;

// const Wrapper = styled.ul`
//   > li {
//     padding-left: 30px;

//     &::before {
//       content: '';
//       display: block;
//       position: relative;
//       height: 5px;
//       width: 5px;
//       left: -30px;
//       top: 15px;
//       border-radius: 10px;
//       background-color: black;
//     }
//   }
// `;

